.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.question {
  margin: 10px;
  font-size: large;
  font-weight: bold;
}

.reveal-button {
  margin: 10px;
  margin-left:auto;
  margin-right:auto;
}

.score-button {
  margin: 5px;
}

.answer-prompt {
  margin: 5px;
}

.answers {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

/* .multi-column {
  column-count: 3;
  column-gap: 1rem;
}

@media (min-width: 576px) {
  .multi-column {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .multi-column {
    column-count: 3;
  }
} */

.answer {
  margin: 5px;
}

.card {
  max-width: 1000px;
  margin: 5px;
}

.state-input {
  width:auto;
  margin-top: 30px;
  margin-bottom: 30px;
}